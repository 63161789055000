nav {
  font-size:100px;
  color: green;
}

h1 {
  text-align: center;
  margin-bottom: -50px;
}

img {
  aspect-ratio: 1;
}


p {
  font-size: 25px;
  text-align: left;
  margin-left: 20px;
}

h6 {
  text-align: center;
}

.articlesList {
  text-align: left;
}

.subTitle {
  font-size: 100px;
  margin-bottom: -50px;
  background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);   -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.home {
  font-size:50px;
  color: green;
  text-align: center;
}

.listItem {
  margin-bottom: 100px;
  display: flex;
  margin: auto;
  align-items: center;
}

.articleLink {
  display: inline;
  margin-left: 20px;
  font-size: 50px;
  offset: 100px;
}

.articleLinkImage {
  max-width: 120px;
  max-height: 120px;
  margin-left: 50px;
}

.articleImage {
  display: block;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.App {
  max-width: 1000px;
  margin: auto;
  background-color: lightblue;
  margin-bottom: 100px;
}